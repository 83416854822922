import { Injectable } from "@angular/core";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { DatePipe } from "@angular/common";



@Injectable({
  providedIn: "root",
})
export class ExportExcelService {
  // Redmeption



  hasBeat: any;

  constructor(
    private datePipe: DatePipe,

  ) {

    this.hasBeat = localStorage.getItem("has_beat");
  }

  pipe = new DatePipe("en-US");

  // Export excel general

  exportExcel_with_header(headers, excel_name) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excel_name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        excel_name + "_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  

  commonDownloadWithData(excelData) {
    const finalHeaders = excelData.headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelData.name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });



    worksheet.columns = excelData.column
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    if (excelData.body.length > 0) {
      excelData.body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    // only if hasDropdown param is true this code is executed
    if (excelData.hasDropdown == true) {
      excelData.dropDowns.forEach(dropdown => {

        let commonWorksheet = workbook.addWorksheet(dropdown.name, {
          pageSetup: {
            horizontalCentered: true,
            verticalCentered: true,
            paperSize: 9,
            orientation: "portrait",
            margins: {
              left: 0.3149606,
              right: 0.3149606,
              top: 0.3543307,
              bottom: 0.3543307,
              header: 0.3149606,
              footer: 0.3149606,
            },
          },
        });

        //Add Header Row
        let commonHeaderRow = commonWorksheet.addRow(dropdown.name);

        // Cell Style : Fill and Border
        commonHeaderRow.eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "9999FF" },
            bgColor: { argb: "FF0000FF" },
          };
          cell.font = {
            bold: true,
            name: "Calibri",
            size: 8,
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        commonWorksheet.columns = [{ header: dropdown.name, key: dropdown.key }];

        dropdown.body.forEach((d) => {
          let row = commonWorksheet.addRow(d);
          (row.font = {
            name: "Calibri",
            size: 8,
          }),
            (row.alignment = {
              vertical: "middle",
              horizontal: "center",
            });
        });

        commonWorksheet.columns.forEach(function (column, i) {
          column.width = 26;
        });


        let Formulae = [`${dropdown.name}!A2:A${dropdown.body.length + 1}`];

        for (var i = 2; i < 50; i++) {
          worksheet.getCell(`${dropdown.column}${i}`).dataValidation = {
            type: "list",
            allowBlank: false,
            formulae: Formulae,
            errorStyle: "error",
            errorTitle: "choose a status",
            error: "please select",
            showErrorMessage: true,
          };
        }

      });
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        `${excelData.file}` + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  public DownloadstaticReportExcel(name, sheetname, tableData, excelArray) {
    let header = tableData.headers

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "629CCA" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Arial",
        size: 10,
        color: { argb: "FFFFFF" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = excelArray;

    if (tableData.length > 0) {
      tableData.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 10,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });

      });
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        name +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }



  productExcelDownload(state, excelHeader, productHeader, fileName, sheetName1, sheetName2, excelStatic, extraSheets) {
    const finalHeaders = excelHeader;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetName1, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.protection = {
        locked: false,
      };
    });
    // static row
    //Add Header Row
    let staticRow = worksheet.addRow(excelStatic);
    // Add Geo Header Row
    // Cell Style : Fill and Border
    staticRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFff" },
        bgColor: { argb: "FFFFFF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.protection = {
        locked: false,
      };
    });
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });




    /************STATE WORKSHEET***********************/
    let stateWorksheet = workbook.addWorksheet(sheetName2, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(productHeader);
    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    stateWorksheet.columns = [{ header: "City", key: "city_name" }];
    state.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });
    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });
    let stateFormulae = [`${stateWorksheet.name}!A2:A${state.length + 1}`];
    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`I${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }


    /************ channel WORKSHEET***********************/
    let channelWorksheet = workbook.addWorksheet(extraSheets.sheetName3, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let channelHeaderRow = channelWorksheet.addRow(extraSheets.channelHeader);
    // Cell Style : Fill and Border
    channelHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    channelWorksheet.columns = [{ header: "Channels", key: "channel_name" }];
    extraSheets.channels.forEach((d) => {
      let row = channelWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });
    channelWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });
    let channelFormulae = [`${channelWorksheet.name}!A2:A${extraSheets.channels.length + 1}`];
    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`G${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: channelFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }










    /************ rigion WORKSHEET***********************/
    let rigionWorksheet = workbook.addWorksheet(extraSheets.sheetName4, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let rigionHeaderRow = rigionWorksheet.addRow(extraSheets.regionlHeader);
    // Cell Style : Fill and Border
    rigionHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    rigionWorksheet.columns = [{ header: "Region", key: "region_name" }];
    extraSheets.regions.forEach((d) => {
      let row = rigionWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });
    rigionWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });
    let regionFormula = [`${rigionWorksheet.name}!A2:A${extraSheets.regions.length + 1}`];
    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`J${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: regionFormula,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }





    /************ Channel Segment WORKSHEET***********************/
    let segmentWorkSheet = workbook.addWorksheet(extraSheets.segment_sheet, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let segmentHeaderRow = segmentWorkSheet.addRow(extraSheets.channelSegmentHeader);
    // Cell Style : Fill and Border
    segmentHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    segmentWorkSheet.columns = [{ header: "Channel Segments", key: "segment_name" }];
    extraSheets.channelSegment.forEach((d) => {
      let row = segmentWorkSheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });
    segmentWorkSheet.columns.forEach(function (column, i) {
      column.width = 26;
    });
    let segmentFormula = [`${segmentWorkSheet.name}!A2:A${extraSheets.channelSegment.length + 1}`];
    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`F${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: segmentFormula,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }







    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        fileName + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }














  productsmapleTemplate(headers, filename, dropdownList) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('sheet1', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },

    });

    //Add Header Row 
    let headerRow = worksheet.addRow(finalHeaders);

    // Add Geo Header Row


    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };

      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };

      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    /************Category WORKSHEET***********************/
    let categoryWorksheet = workbook.addWorksheet("Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let categoryHeaderRow = categoryWorksheet.addRow("Category");
    // Cell Style : Fill and Border
    categoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    
    categoryWorksheet.columns = [{ header: "category", key: "name" }];
    dropdownList.category.forEach((d) => {
      let row = categoryWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    let categoryFormulae = [`${categoryWorksheet.name}!A2:A${dropdownList.category.length + 1}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`A${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: categoryFormulae,
        errorStyle: "error",
        errorTitle: "choose a category",
        error: "please select",
        showErrorMessage: true,
      };
    }

    
    /***************************TypeWorkSheet*************************/
    let TypeWorksheet = workbook.addWorksheet("Type", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let TypeHeaderRow = TypeWorksheet.addRow("Type");
    // Cell Style : Fill and Border
    TypeHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    
    TypeWorksheet.columns = [{header : "Type" ,key:"product_type"}];
    dropdownList.Type.forEach((d) => {
      let row = TypeWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    TypeWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    // let categoryFormulae = [`${categoryWorksheet.name}!A2:A${dropdownList.category.length + 1}`];
    let TypeFormulae = [`${TypeWorksheet.name}!G2:A${dropdownList.Type.length + 1}`];

    for (var i = 2; i < 10; i++) {
      worksheet.getCell(`G${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: TypeFormulae,
        errorStyle: "error",
        errorTitle: "choose a category",
        error: "please select",
        showErrorMessage: true,
      };
    }

    //////////////////////////////////////////////////////////
    
  /*************************ProductBy*********************************/
  
  let ProductBysheet = workbook.addWorksheet("ProductBy", {
    pageSetup: {
      horizontalCentered: true,
      verticalCentered: true,
      paperSize: 9,
      orientation: "portrait",
      margins: {
        left: 0.3149606,
        right: 0.3149606,
        top: 0.3543307,
        bottom: 0.3543307,
        header: 0.3149606,
        footer: 0.3149606,
      },
    },
  });
  //Add Header Row
  let ProductByHeaderRow = ProductBysheet.addRow("ProductBy");
  // Cell Style : Fill and Border
  ProductByHeaderRow.eachCell((cell, number) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9999FF" },
      bgColor: { argb: "FF0000FF" },
    };
    cell.font = {
      bold: true,
      name: "Calibri",
      size: 10,
    };
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });
  
  ProductBysheet.columns = [{header : "ProductBy" ,key:"product_by"}];
  dropdownList.Product_By.forEach((d) => {
    let row = ProductBysheet.addRow(d);
    (row.font = {
      name: "Calibri",
      size: 10,
    }),
      (row.alignment = {
        vertical: "middle",
        horizontal: "center",
      });
  });

  ProductBysheet.columns.forEach(function (column, i) {
    column.width = 26;
  });


  let ProductByFormulae = [`${ProductBysheet.name}!J2:A${dropdownList.Product_By.length + 1}`];

  for (var i = 2; i < 50; i++) {
    worksheet.getCell(`J${i}`).dataValidation = {
      type: "list",
      allowBlank: false,
      formulae: ProductByFormulae,
      errorStyle: "error",
      errorTitle: "choose a category",
      error: "please select",
      showErrorMessage: true,
    };
  }


  /***************************************************************** */
    // Add Data and Conditional Formatting

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      fs.saveAs(
        blob,
        filename + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }


/************************************************************************* */
 




ProductVariationMastersTemplate(headers, filename, dropdownList) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('sheet2', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },

    });

    //Add Header Row 
    let headerRow = worksheet.addRow(finalHeaders);

    // Add Geo Header Row


    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };

      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };

      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


  /************ Product WORKSHEET***********************/
  
    let ProductWorksheet = workbook.addWorksheet("Product", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let ProductHeaderRow = ProductWorksheet.addRow("Product");
    // Cell Style : Fill and Border
    ProductHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    
    ProductWorksheet.columns = [{ header: "Product", key: "name" }];
    dropdownList.Product.forEach((d) => {
      let row = ProductWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    let ProductFormulae = [`${ProductWorksheet.name}!A2:A${dropdownList.Product.length + 1}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`A${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: ProductFormulae,
        errorStyle: "error",
        errorTitle: "choose a category",
        error: "please select",
        showErrorMessage: true,
      };
    }

    
    /***************************VAriationMAstersWorkSheet*************************/
    
    let VariationMastersWorksheet = workbook.addWorksheet("VariationMasters", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let VariationMastersHeaderRow = VariationMastersWorksheet.addRow("VariationMasters");
    // Cell Style : Fill and Border
    VariationMastersHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    
    VariationMastersWorksheet.columns = [{ header: "VariationMasters", key: "name" }];
    dropdownList.VariationMasters.forEach((d) => {
      let row = VariationMastersWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    let VariationMasterFormulae = [`${VariationMastersWorksheet.name}!B2:A${dropdownList.VariationMasters.length + 1}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: VariationMasterFormulae,
        errorStyle: "error",
        errorTitle: "choose a category",
        error: "please select",
        showErrorMessage: true,
      };
    }

    /************************************************************* */
    
    
    
    /***************************VAriationWorkSheet*************************/
    let VariationWorksheet = workbook.addWorksheet("Variation", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let VariationHeaderRow = VariationWorksheet.addRow("Variation");
    // Cell Style : Fill and Border
    VariationHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    
    VariationWorksheet.columns = [{ header: "Variation", key: "name" }];
    dropdownList.Variation.forEach((d) => {
      let row = VariationWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    let VariationFormulae = [`${VariationWorksheet.name}!C2:A${dropdownList.Variation.length + 1}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`C${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: VariationFormulae,
        errorStyle: "error",
        errorTitle: "choose a category",
        error: "please select",
        showErrorMessage: true,
      };
    }

    /************************************************************* */
    
    
    // Add Data and Conditional Formatting

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      fs.saveAs(
        blob,
        filename + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }  














}